import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import storeImage from '../../assets/images/store-image.png';
import storeAnimation from '../../assets/images/store-animation.gif';
import storeAnimationMob from '../../assets/images/store-animation-mob.gif';
import storeAvailable from '../../assets/images/store-available.png';
import Button from './Button';
import giftImage from '../../assets/images/gift.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft,
  faStore,
  faShoppingCart,
} from '@fortawesome/pro-regular-svg-icons';
import useGlobal from '../store';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

function detectMob() {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
}

const Store = (props) => {
  const [openStore, setOpenStore] = useState(true);
  const [globalState, globalActions] = useGlobal();

  const iframe = useRef();

  useEffect(() => {
    if (!props.blockedButton) {
      if (props.state) {
        iframe.current.style.display = 'flex';
      } else {
        iframe.current.style.display = 'none';
      }
    }
  }, [props.state, props.blockedButton]);

  const updateIframe = (e) => {
    if (iframe.current && !props.blockedButton) {
      if (iframe.current.contentWindow.document.getElementById('store-back')) {
        console.log(iframe.current.contentWindow.document.location);
        iframe.current.contentWindow.document
          .getElementById('store-back')
          .addEventListener('click', function () {
            if (
              iframe.current.contentWindow.location.href ===
                location.origin + '/products'
            ) {
              props.click();
            } else {
              iframe.current.contentWindow.parent.history.back();
            }
          });
      }

      if (
        iframe.current.contentWindow.document.getElementById('order_summary') !=
        null
      ) {
        axios
          .get(`application/dashboard.json`)
          .then((res) => {
            globalActions.updateResponse(res.data.response);
          })
          .catch((error) => {});
      }
      if (
        iframe.current.contentWindow.document.getElementById(
          'checkout-button-id'
        )
      ) {
        iframe.current.contentWindow.document
          .getElementById('checkout-button-id')
          .addEventListener('click', function () {
            props.click();
            iframe.current.contentWindow.document.location.href =
              location.origin + '/products';
            updateIframe();
          });
      }

      if (
        iframe.current.contentWindow.document.getElementById('account') != null
      ) {
        navigate.push(`/application/login`);
      }
    }
  };

  return (
    <div className={'store'} id={'store'}>
      <div
        className="store__initial-box store-initial-box"
        id="store-initial-box">
        <div className="store-initial-box__content">
          <p className="store-initial-box__title">
            Exchange <b>your points</b>
          </p>
          <Button
            iconToLeft={true}
            icon={<img src={giftImage} className="button__image" />}
            onclick={props.blockedButton ? null : props.click}
            className={'button button--primary'}
            text={' Prizes'}
          />
        </div>
        <div className={'store-initial-box__image'}>
          {detectMob() ? (
            <img src={storeAnimationMob} alt="store-animation-mob" />
          ) : (
            <img src={storeAnimation} alt="store-animation" />
          )}
        </div>
      </div>
      <div
        className="store__expanded-box store-expanded-box"
        id="store-expanded-box">
        <div className="store-expanded-box__content" id="store-content-box">
          {/* <Button
            onclick={props.blockedButton ? null : props.click}
            className={'button button--back'}
            text={<FontAwesomeIcon icon={faArrowLeft} />}
          /> */}
          {/* <div className="store-expanded-box__text">
            <p className="store-expanded-box__title">
              Recolha <br /> <b>o seu prémio</b>
            </p>
            <p className="store-expanded-box__subtitle">
              De momento a loja encontra-se fechada. Conteúdo brevemente
              disponível.
            </p>
          </div>
          <div className={'store-expanded-box__image'}>
            <img src={storeAnimation} alt="new" />
          </div> */}
        </div>
        <div
          className="store-expanded-box__iframe"
          style={{ display: openStore ? 'flex' : 'none' }}>
          <iframe
            src="/products"
            onLoad={updateIframe}
            ref={iframe}
            id="store-iframe"
          />
        </div>

        {/* <div className="store-expanded-box__actions" id="store-content-actions">
          <Button
            iconToLeft={true}
            icon={<img src={giftImage} className="button__image" />}
            onclick={props.blockedButton ? null : props.click}
            className={'button button--primary'}
            text={' Prémios'}
          />
        </div> */}
      </div>
    </div>
  );
};

export default Store;
