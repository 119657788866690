require('dotenv').config();
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import BarcodeScannerComponent from 'react-qr-barcode-scanner';
import MaskedInput from 'react-maskedinput';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes,
  faArrowLeft,
  faExclamationTriangle,
} from '@fortawesome/pro-regular-svg-icons';

import useGlobal from '../store';
import Button from './Button';
import Scanner from './Scanner';

import scanAnimation from '../../assets/images/scan-animation.gif';

import scanTypoAnimation from '../../assets/images/scan-typo-animation.gif';

import scanAnimationMob from '../../assets/images/scan-animation-mob.gif';

import scanTypoAnimationMob from '../../assets/images/scan-typo-animation-mob.gif';

import success from '../../assets/images/success.png';
import phoneImage from '../../assets/images/phone.svg';
import errorImage from '../../assets/images/error.png';
import barcodeImage from '../../assets/images/barcode.png';

import { OnScanScreenEnter } from '../animations/animation';

function detectMob() {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const Scan = (props) => {
  const [mounted, setMounted] = useState(false);
  let navigate = useHistory();

  const [globalState, globalActions] = useGlobal();
  const [isReaderOpen, setIsReaderOpen] = useState(false);

  const [scanState, setScanState] = useState('');
  const [scanBlockErrorButton, setScanBlockErrorButton] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);

  const [cameraPermission, setCameraPermission] = useState(false);
  const [submitLock, setSubmitLock] = useState(false);

  const [codeReader, setCodeReader] = useState(null);

  useEffect(() => {
    let timer;
    if (globalState.tenant !== 'bebidasmaiscirculares') {
      if (detectMob()) {
        if (props.startCamera) {
          // requestCameraPermissions();
          setTimeout(() => {
            requestCameraPermissions();
          }, 2000);
        } else {
          setMounted(false);
        }
      } else {
        if (props.startCamera) {
          requestCameraPermissions();
          timer = setInterval(() => {
            requestCameraPermissions();
          }, 2000);
        } else {
          setMounted(false);
        }
      }
    }
    return () => (timer ? clearInterval(timer) : {});
  }, [props.startCamera]);

  const handleSubmit = (cameraCode, type) => {
    if (globalState.user.id) {
      props.submitHandler(cameraCode, type);
    } else if (cameraCode && cameraCode.length > 0) {
      navigate.push(`/application/login?code=${cameraCode}`);
      setMounted(false);
    } else {
      navigate.push(
        `/application/login?code=${props.code.replaceAll(' ', '')}`
      );
      setMounted(false);
    }
  };

  const updateStripCode = (value) => {
    let newValue = value.replaceAll(' ', '');
    props.setCode(newValue);
  };

  const openManualInputCodeModal = () => {
    setModalOpen(true);
  };

  const requestCameraPermissions = () => {
    try {
      if (
        navigator &&
        navigator.mediaDevices &&
        navigator.mediaDevices.getUserMedia
      ) {
        navigator.mediaDevices
          .getUserMedia({ audio: false, video: true })
          .then((stream) => {
            setMounted(true);
            setCameraPermission(true);
          })
          .catch((err) => {
            // setMounted(false);
            // setCameraPermission(false);
          });
      } else {
        navigator.getUserMedia(
          { video: true, audio: false },
          function (localMediaStream) {
            setMounted(true);
            setCameraPermission(true);
          },
          function (err) {
            // setMounted(false);
            // setCameraPermission(false);
          }
        );
      }
    } catch (e) {}
  };

  if (scanState == '') {
    return (
      <div className={'scan'}>
        <div
          className={'scan__initial-box scan-initial-box'}
          id="scan-initial-box">
          <div className="scan-initial-box__image ">
            {detectMob() ? (
              globalState.tenant == 'bebidasmaiscirculares' ? (
                <img src={scanTypoAnimationMob} alt="scan-animation-mob" />
              ) : (
                <img src={scanAnimationMob} alt="scan-animation-mob" />
              )
            ) : globalState.tenant == 'bebidasmaiscirculares' ? (
              <img src={scanTypoAnimation} alt="scan-animation" />
            ) : (
              <img src={scanAnimation} alt="scan-animation" />
            )}
          </div>
          <div className="scan__initial-box-text">
            <div className="scan-initial-box__title">
              {globalState.tenant == 'bebidasmaiscirculares' ? (
                <div>
                  {' '}
                  Introduza <br /> <b>o código do talão.</b>{' '}
                </div>
              ) : (
                <div>
                  {' '}
                  Scan <br /> <b>the barcode</b>{' '}
                </div>
              )}
            </div>
          </div>
          <div className="scan-initial-box__actions">
            <Button
              iconToLeft={true}
              icon={<img src={phoneImage} className="button__image" />}
              onclick={
                props.blockedButton
                  ? null
                  : props.state == false
                  ? props.click
                  : props.submitHandler
              }
              className={'button button--primary'}
              text={' Scan Barcode'}
            />
          </div>
        </div>
        <div
          className={'scan__expanded-box scan-expanded-box'}
          id="scan-expanded-box">
          <div className="scan-expanded-box__content" id="scan-content-box">
            <Button
              onclick={() => {
                if (props.blockedButton == false) {
                  props.click();
                  setMounted(false);
                }
              }}
              className={'button button--back'}
              text={<FontAwesomeIcon icon={faArrowLeft} />}
            />
            <div className={'scan-exapanded-box__text'}>
              <div className="scan-expanded-box__title">
                {globalState.tenant == 'bebidasmaiscirculares' ? (
                  <div>
                    {' '}
                    Enter <br /> <b>the code on your receipt.</b>{' '}
                  </div>
                ) : (
                  <div>
                    {' '}
                    Scan <br /> <b>Your barcode</b>{' '}
                  </div>
                )}
              </div>
              <div className="scan-expanded-box__subtitle">
                {globalState.tenant == 'bebidasmaiscirculares' ? (
                  <div>
                    {' '}
                    Enter the 9 numbers on your receipt to receive points.{' '}
                  </div>
                ) : (
                  <div>
                    {' '}
                    Scan the barcode or manually enter the code on your receipt.{' '}
                  </div>
                )}
              </div>
            </div>
            <div className={'scan-expanded-box__image'}>
              {detectMob() ? (
                globalState.tenant == 'bebidasmaiscirculares' ? (
                  <img src={scanTypoAnimationMob} alt="scan-animation-mob" />
                ) : (
                  <img src={scanAnimationMob} alt="scan-animation-mob" />
                )
              ) : globalState.tenant == 'bebidasmaiscirculares' ? (
                <img src={scanTypoAnimation} alt="scan-animation" />
              ) : (
                <img src={scanAnimation} alt="scan-animation" />
              )}
            </div>
          </div>

          <div className="scan-expanded-box__input" id="scan-input-box">
            {globalState.tenant !== 'bebidasmaiscirculares' ? (
              <>
                {cameraPermission && mounted ? (
                  <div className="barcode">
                    <Scanner
                      handleSubmit={handleSubmit}
                      updateStripCode={updateStripCode}
                      codeReader={codeReader}
                      setCodeReader={setCodeReader}
                    />
                    <img
                      src={barcodeImage}
                      alt="barcode"
                      className={'barcode-icon'}
                    />
                  </div>
                ) : (
                  <div className="permissions-box">
                    <FontAwesomeIcon
                      icon={faExclamationTriangle}
                      color="orange"
                    />
                    <label className="permissions-box__text">
                      You need to enable the camera in your browser settings to be able to scan the barcode
                    </label>
                  </div>
                )}
              </>
            ) : (
              // <PinInput
              //   length={9}
              //   focus={true}
              //   divider={3}
              //   // disabled
              //   // secret
              //   // ref={(p) => (pin = p)}
              //   type="numeric"
              //   onChange={(value) => {
              //     //  updateNewCode(value);
              //   }}
              //   inputStyle={{
              //     borderRadius: 10,
              //     borderColor: 'lightgrey',
              //     boxShadow: '0 0 5pt 0.5pt #D3D3D3',
              //     backgroundColor: '#fff',
              //   }}
              //   inputFocusStyle={{
              //     boxShadow: '0 0 5pt 1pt red',
              //     outlineWidth: 0,
              //   }}
              //   style={{ display: 'flex', flexDirection: 'row' }}
              // />
              <MaskedInput
                mask="1 1 1 1 1 1 1 1 1"
                name="code"
                size="17"
                onChange={(e) => updateStripCode(e.target.value)}
                value={props.code}
                className={'input-masked'}
                autoComplete="off"
                inputMode="decimal"
              />
            )}
          </div>
          <div className="scan-expanded-box__actions" id="scan-content-actions">
            {globalState.tenant !== 'bebidasmaiscirculares' ? (
              <Button
                onclick={openManualInputCodeModal}
                className={'button button--primary'}
                text={'Enter code manually'}
              />
            ) : (
              <Button
                onclick={() => handleSubmit(undefined, 'manual')}
                className={'button button--primary'}
                text={'Validate Code'}
              />
            )}
          </div>
        </div>
        <div className={'scan__loading-screen'} id="scan-loading-screen">
          <div className="scan__loading-screen-animation"></div>
        </div>
        <div className={'scan__success-screen'} id="scan-success-screen">
          <div className={'scan__success-message'}>
            Code <b>successfully</b> submitted!
          </div>
          <div className="scan__success-img-section">
            <img src={success} alt="error" className="scan__success-image" />
          </div>
          <div className="scan-success-screen__actions">
            <Button
              onclick={props.scanBlockButton ? null : props.successClick}
              className={'button button--primary'}
              text={'Finish'}
            />
          </div>
        </div>
        <div
          className={'scan__error-screen scan-error-screen'}
          id="scan-error-screen">
          <div className="scan__error">
            <span className="scan__error-message">{props.errorMessage}</span>
          </div>
          <div className="scan__error-img-section">
            <img src={errorImage} alt="error" className="scan__error-image" />
          </div>
          <div className="scan-error-screen__actions">
            <Button
              onclick={() => {
                if (!props.scanBlockButton) {
                  OnScanScreenEnter(
                    props.scanBlockButton,
                    props.setScanBlockButton,
                    '#scan-error-screen'
                  );
                  setMounted(false);
                }
              }}
              className={'button button--primary'}
              text={'Tentar Novamente'}
            />
          </div>
        </div>

        <Modal isOpen={modalOpen} style={customStyles} ariaHideApp={false}>
          <div className="input-masked-header">
            <label className="input-masked-title">
              Enter code manually
            </label>
            <FontAwesomeIcon
              icon={faTimes}
              size="xs"
              color="lightgrey"
              onClick={() => setModalOpen(false)}
            />
          </div>
          <MaskedInput
            mask="1 1 1 1 1 1 1 1 1 1 1 1 1 1 1 1 1 1 1 1 1 1 1 1 1 1 1 1"
            name="code"
            size="28"
            onChange={(e) => {
              props.setCode(e.target.value);
              if (
                e.target.value.replaceAll(' ', '').replaceAll('_', '')
                  .length === 28
              ) {
                setSubmitLock(true);
              } else {
                setSubmitLock(false);
              }
            }}
            value={props.code}
            className={'input-masked-modal'}
            autoComplete="off"
            inputMode="decimal"
          />
          {submitLock ? (
            <button
              className="button button--primary"
              onClick={() => {
                setModalOpen(false);
                handleSubmit(undefined, 'manual');
              }}>
              Submit
            </button>
          ) : (
            <button className="button button--primary-disabled">
              Submit
            </button>
          )}
        </Modal>
      </div>
    );
  }
};

export default Scan;
