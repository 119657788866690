import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import useGlobal from '../store';
import FormInput from '../components/FormInput';
import {
  isValidEmail,
  isPhoneValid,
  isPasswordValid,
  ifBothPasswordsValid,
} from '../validators/validators';
import Button from '../components/Button';
import queryString from 'query-string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import Checkbox from '../components/Checkbox';
import TermsModal from '../components/TermsModal';

const Register = (props) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [acceptedTOS, setAcceptedTOS] = useState(false);
  const [acceptedReceiveEmails, setAcceptedReceiveEmails] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [globalState, globalActions] = useGlobal();
  const [errorEmailMessage, setErrorEmailMessage] = useState('');
  const [errorConfirmPassword, setErrorConfirmPassword] = useState('');
  const [errorPassword, setErrorPassword] = useState('');
  const [errorPhoneMessage, setErrorPhoneMessage] = useState('');
  const [isShortPassword, setIsShortPassword] = useState(false);
  const [code, setCode] = useState('');
  const [modalOpen, setModalOpen] = useState(false);

  let navigate = useHistory();

  useEffect(() => {
    const urlParams = queryString.parse(window.location.search);

    if (Object.keys(urlParams).length > 0) {
      setCode(urlParams?.code);
    }
  }, []);

  const submitHandler = () => {
    let data = new FormData();
    setErrorEmailMessage('');
    setErrorConfirmPassword('');
    data.append('spree_user[name]', name);
    data.append('spree_user[email]', email);
    data.append('spree_user[phone]', phone);
    data.append('spree_user[password]', password);
    data.append('spree_user[password_confirmation]', passwordConfirm);
    data.append('spree_user[accepted_tos]', acceptedTOS);
    data.append('spree_user[accepted_receive_emails]', acceptedReceiveEmails);

    axios
      .post('/signup', data, {
        headers: {
          'Content-Type': 'application/javascript',
          Accept: 'application/javascript',
        },
        withCredentials: true,
      })
      .then((res) => {
        if (res.status == 200) {
          globalActions.updateResponse(res.data.response);
          if (code) {
            navigate.push(`/application/dashboard?code=${code}`);
            props.message('Your registration was successful');
            props.messageType(true);
            props.modal(true);
          } else {
            navigate.push(`/application/dashboard`);
            props.message('Your registration was successful');
            props.messageType(true);
            props.modal(true);
          }
        }
      })
      .catch(function (error) {
        props.message(error.response.data.response.error.message);
        props.messageType(false);
        props.modal(true);
      });
  };

  const navigateToLogin = () => {
    if (code) {
      navigate.push(`/application/login?code=${code}`);
    } else {
      navigate.push('/application/login');
    }
  };

  const navigatePdf = () => {
    window.open('https://dovelhosefaznovo.pt/doc/RVM_Regulamento.pdf');
  };

  const policy = () => {
    window.open('https://dovelhosefaznovo.pt/doc/DVSFN_Privacy.pdf');
  };

  const contact = () => {
    window.open('https://dovelhosefaznovo.pt/contatos');
  };

  const navigatePdfCircular = () => {
    window.open(
      'https://www.bebidascirculares.pt/wp-content/uploads/2022/01/RVM_Regulamento_BC_07.02.2022_14fev.pdf'
    );
  };

  const circularPolicy = () => {
    window.open('https://www.bebidascirculares.pt/politica-de-privacidade/');
  };

  const circularContact = () => {
    window.open('https://www.bebidascirculares.pt/contactos/');
  };

  const submitUnlock = () => {
    if (
      name != '' &&
      email != '' &&
      password != '' &&
      passwordConfirm != '' &&
      errorEmailMessage == '' &&
      errorPhoneMessage == '' &&
      errorPassword == '' &&
      errorConfirmPassword == ''
    )
      return true;
    else return false;
  };

  return (
    <div className="card register">
      <div className="register__header">
        <div className="card__top-wrapper">
          <Button
            onclick={navigateToLogin}
            className={'button button--back'}
            text={<FontAwesomeIcon icon={faArrowLeft} />}
          />
          <div className="card__title"> {'Sign Up'} </div>
        </div>
      </div>
      <div className="register__content">
        <FormInput
          label={'Name'}
          onchange={(e) => setName(e.target.value)}
          value={name}
          type={'text'}
          name={'spree_user[name]'}
          placeholder={'Enter your name'}
          needValidation={false}
          autoComplete="username"
        />

        <FormInput
          label={'Phone Number (optional)'}
          onchange={(e) => {
            setPhone(e.target.value);
            if (e.target.value == '') {
              setErrorPhoneMessage('');
            } else if (!isPhoneValid(e.target.value)) {
              setErrorPhoneMessage('Invalid number');
            } else {
              setErrorPhoneMessage('');
            }
          }}
          value={phone}
          type={'tel'}
          name={'spree_user[phone]'}
          checker={isPhoneValid(phone)}
          placeholder={'Enter your phone number'}
          needValidation={true}
          errorText={errorPhoneMessage}
          autoComplete="phone"
        />

        <FormInput
          label={'E-mail'}
          onchange={(e) => {
            setEmail(e.target.value);
            if (e.target.value == '') {
              setErrorEmailMessage('');
            } else if (!isValidEmail(e.target.value)) {
              setErrorEmailMessage('Invalid Email');
            } else {
              setErrorEmailMessage('');
            }
          }}
          value={email}
          type={'email'}
          name={'spree_user[email]'}
          checker={isValidEmail(email)}
          placeholder={'Enter your e-mail'}
          errorText={errorEmailMessage}
          needValidation={true}
          autoComplete="email"
        />

        <FormInput
          label={'Password'}
          onchange={(e) => {
            setPassword(e.target.value);
            if (e.target.value == '') {
              setErrorPassword('');
              setIsShortPassword(false);
            } else if (e.target.value.length < 6) {
              setErrorPassword('Password must have at least 6 characters');
              if (passwordConfirm != '') {
                if (e.target.value == passwordConfirm) {
                  setErrorConfirmPassword('');
                } else {
                  setErrorConfirmPassword('Passwords not matching');
                }
              }
              setIsShortPassword(true);
            } else {
              setErrorPassword('');
              setIsShortPassword(false);
              if (passwordConfirm != '') {
                if (e.target.value == passwordConfirm) {
                  setErrorConfirmPassword('');
                } else {
                  setErrorConfirmPassword('Passwords not matching');
                }
              }
            }
          }}
          value={password}
          type={'password'}
          name={'spree_user[password]'}
          placeholder={'Enter your password'}
          checker={isPasswordValid(password, passwordConfirm)}
          needValidation={true}
          errorText={errorPassword}
          autoComplete="off"
        />

        <FormInput
          label={'Confirm Password'}
          onchange={(e) => {
            setPasswordConfirm(e.target.value);
            if (e.target.value == '') {
              setErrorConfirmPassword('');
            } else if (isShortPassword) {
              setErrorConfirmPassword('');
            } else if (!ifBothPasswordsValid(password, e.target.value)) {
              setErrorConfirmPassword('Enter your password');
            } else {
              setErrorConfirmPassword('');
            }
          }}
          value={passwordConfirm}
          type={'password'}
          name={'spree_user[password_confirmation]'}
          placeholder={'Passwords not matching'}
          checker={ifBothPasswordsValid(password, passwordConfirm)}
          needValidation={true}
          errorText={errorConfirmPassword}
          autoComplete="off"
        />

        <div className="register__checkbox">
          {globalState.tenant === 'bebidasmaiscirculares' ? (
            <Checkbox
              label={[
                'I accept and agree to the ',
                <a
                  className={'checkbox__link'}
                  onClick={(e) => {
                    e.preventDefault();
                    navigatePdfCircular();
                    return false;
                  }}>
                  Terms and Conditions
                </a>,
                ' and authorize the processing of my personal data in accordance with the ',
                <a
                  className={'checkbox__link'}
                  onClick={(e) => {
                    e.preventDefault();
                    circularPolicy();
                    return false;
                  }}>
                  Privacy Policy
                </a>,
                '. For the purpose of rectification or deletion of personal data, a request should be sent via the ',
                <a
                  className={'checkbox__link'}
                  onClick={(e) => {
                    e.preventDefault();
                    circularContact();
                    return false;
                  }}>
                  contact form
                </a>,
                ', indicating the name and email adress associated with the account.',
              ]}
              onchange={(e) => {
                setAcceptedTOS(e.target.checked);
              }}
              value={acceptedTOS}
              name={'spree_user[accepted_tos]'}
            />
          ) : (
            <Checkbox
              label={[
                'I accept and agree to the ',
                <a
                  className={'checkbox__link'}
                  onClick={(e) => {
                    e.preventDefault();
                    navigatePdf();
                    return false;
                  }}>
                  Terms and Conditions
                </a>,
                ' and authorize the processing of my personal data in accordance with the ',
                <a
                  className={'checkbox__link'}
                  onClick={(e) => {
                    e.preventDefault();
                    policy();
                    return false;
                  }}>
                  Privacy Policy
                </a>,
                '. For the purpose of rectification or deletion of personal data, a request should be sent via the ',
                <a
                  className={'checkbox__link'}
                  onClick={(e) => {
                    e.preventDefault();
                    contact();
                    return false;
                  }}>
                  contact form
                </a>,
                ', indicating the name and email adress associated with the account.',
              ]}
              onchange={(e) => {
                setAcceptedTOS(e.target.checked);
              }}
              value={acceptedTOS}
              name={'spree_user[accepted_tos]'}
            />
          )}

          {globalState.tenant == 'bebidasmaiscirculares' ? (
            <Checkbox
              label={
                'I agree to receive communications and informations from the project Bebidas + Circulares'
              }
              onchange={(e) => {
                setAcceptedReceiveEmails(e.target.checked);
              }}
              value={acceptedReceiveEmails}
              name={'spree_user[accepted_receive_emails]'}
            />
          ) : (
            <Checkbox
              label={
                'I agree to receive communications and informations from the project Do Velho Se Faz Novo'
              }
              onchange={(e) => {
                setAcceptedReceiveEmails(e.target.checked);
              }}
              value={acceptedReceiveEmails}
              name={'spree_user[accepted_receive_emails]'}
            />
          )}
        </div>
        <TermsModal
          modalOpen={modalOpen}
          setModalOpen={() => setModalOpen(false)}
        />
        <Button
          onclick={() => (submitUnlock() ? submitHandler() : null)}
          className={
            submitUnlock() ? 'button--primary' : 'button--primary-disabled'
          }
          text={'Sign up'}
        />
      </div>
    </div>
  );
};

export default Register;
