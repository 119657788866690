import React, { useEffect, useState } from 'react';
import Card from '../components/Card';
import Transaction from '../components/Transaction';

const TransactionHistory = () => {
  return (
    <div>
      <Card class={'card'} layout={<Transaction />} />
    </div>
  );
};

export default TransactionHistory;
