import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import useGlobal from '../store';
import coin from '../../assets/images/coin.png';

const Transaction = () => {
  const [globalState, globalActions] = useGlobal();
  const navigate = useHistory();

  const navigateToDashboard = () => {
    navigate.push('/application/dashboard');
  };

  return (
    <div className="transaction">
      <div className="transaction__header">
        <div className="card__top-wrapper">
          <Button
            onclick={navigateToDashboard}
            className={'button button--back'}
            text={<FontAwesomeIcon icon={faArrowLeft} />}
          />
          <div className="card__title"> History </div>
        </div>
      </div>
      <div className="transaction__content">
        {globalState.transactions.length !== 0 ? (
          globalState.transactions.map((transaction) => (
            <div className="transaction__info-section" key={transaction.created_at}>
              <div className="transaction__section">
                <span className="transaction__store"> {transaction.store} -</span>
                <span className="transaction__date">
                  {transaction.created_at}
                </span>
              </div>
              <div className="transaction__info-main">
                <span className="transaction__info-image-section">
                  <img
                    src={coin}
                    alt="pontos"
                    className={'transaction__info-image'}
                  />
                </span>
                <span className="transaction__info">
                  {' '}
                  <span className="transaction__points-label">
                    {' '}
                    {transaction.kind === 'redeem_code' || transaction.kind === 'redeem_code_correction' ? '+' : '-'}
                    {transaction.amount} pontos{' '}
                  </span>
                  <span className="transaction__bottles">
                    {' '}
                    {transaction.kind === 'redeem_code' || transaction.kind === 'redeem_code_correction'
                      ? transaction.packages + ' Recicled Packages'
                      : 'Adquiriu um prémio'}{' '}
                  </span>
                </span>
              </div>
            </div>
          ))
          ) : (
            <span className='transaction__message'> No transactions yet. </span> 
          )}
      </div>
    </div>
  );
};

export default Transaction;