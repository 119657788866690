import React from 'react';
import useGlobal from '../store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/pro-light-svg-icons';
import { useHistory } from 'react-router-dom';
import coin from '../../assets/images/coin.png';

const PointsAndPackages = () => {
  const [globalState, globalActions] = useGlobal();
  const navigate = useHistory();

  const navigateToHistory = () => {
    navigate.push('/application/transactions');
  };

  return (
    <div className="points">
      <div className="points__points-section">
        <span className="points__text">
          <span className="points__number">
            {' '}
            {globalState.dashboard.points}{' '}
          </span>
          <img src={coin} alt="pontos" className={'points__coin'} />
        </span>
        <span className="points__divider"> </span>
        <span className="points__text">
          <span className="points__number">
            {' '}
            {globalState.dashboard.packages}{' '}
          </span>
          <span className="points__bottles"> Recicled Packages </span>
        </span>
      </div>
      <div className="points__historic-section" onClick={navigateToHistory}>
        <FontAwesomeIcon icon={faFileAlt} className="points__historic-icon" />
      </div>
    </div>
  );
};

export default PointsAndPackages;
