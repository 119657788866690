import React, { Fragment, useEffect, useState, useContext } from 'react';
import { BrowserRouter as Router, Redirect } from 'react-router-dom';
import Dashboard from '../pages/Dashboard';
import Login from '../pages/Login';
import Password from '../pages/Password';
import Profile from '../pages/Profile';
import Register from '../pages/Register';
import Navbar from './Navbar';
import TransactionHistory from '../pages/TransactionHistory';
import useGlobal from '../store';
import Email from '../pages/Email';
import Footer from './Footer';
import LoadingScreen from '../pages/LoadingScreen';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/pro-regular-svg-icons';

import {
  ModalSheetProvider,
  ModalSheetContext,
} from '../contexts/ModalSheet.tsx';

import { fadeScreen } from '../animations/animation';

import 'react-tiger-transition/styles/main.min.css';

import { Navigation, Route, Screen, Link, glide } from 'react-tiger-transition';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

glide({
  name: 'glide-left',
});

function App(props) {
  const [globalState, globalActions] = useGlobal();
  const [data, setData] = useState(null);
  const [isPortrait, setIsPortrait] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [message, setMessage] = useState();
  const [messageType, setMessageType] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const { showModal, handleOpen, handleClose } = useContext(ModalSheetContext);

  window.onorientationchange = function () {
    var orientation = window.orientation;
    switch (orientation) {
      case 0:
        //Top side up.
        setIsPortrait(true);
        break;

      case -90:
        //Left side up (turned 90 degrees to the right)
        setIsPortrait(false);
        break;

      case 90:
        //Right side up (turned 90 degrees to the left)
        setIsPortrait(false);
        break;
    }
  };

  useEffect(() => {
    setTimeout(() => {
      fadeScreen(setIsLoading);
    }, 4000);
  });

  useEffect(() => {
    globalActions.updateResponse(props.response);
    setData(props.response);
  }, []);

  const openModal = () => {
    showModal(
      <Fragment>
        <p
          style={{
            fontWeight: 600,
            fontSize: 14,
            color: '#FFFFFF',
            marginBottom: 16,
          }}>
          {message}
        </p>
      </Fragment>,
      true
    );
  };

  if (data === null) return null;

  if (isPortrait) {
    return (
      <>
        <div style={{ display: isLoading ? 'block' : 'none' }}>
          <LoadingScreen />
        </div>
        <div className="app" style={{ display: isLoading ? 'none' : 'block' }}>
          <ModalSheetProvider>
            <div>
              <div
                style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                <Router>
                  <Navigation
                    globalTransitionProps={{
                      classNames: 'glide-left',
                    }}>
                    <Navbar />
                    <Modal isOpen={modalOpen} style={customStyles}>
                      <p>{message}</p>
                      <button
                        className="button button--primary"
                        onClick={() => setModalOpen(false)}>
                        Close
                      </button>
                    </Modal>
                    {globalState.dashboard.finished_project === 'true' ? (
                      <>
                        <Dashboard isLoading={isLoading} />
                        <Redirect to="/" />
                      </>
                    ) : (
                      <Route exact screen path="/application/dashboard">
                        <Dashboard isLoading={isLoading} />
                      </Route>
                    )}
                    <Route exact screen path="/application/login">
                      <Login
                        messageType={setMessageType}
                        message={setMessage}
                        modal={setModalOpen}
                      />
                    </Route>
                    <Route exact screen path="/application/profile">
                      <Profile
                        messageType={setMessageType}
                        message={setMessage}
                        modal={setModalOpen}
                      />
                    </Route>
                    <Route exact screen path="/application/password">
                      <Password
                        messageType={setMessageType}
                        message={setMessage}
                        modal={setModalOpen}
                      />
                    </Route>
                    <Route exact screen path="/application/registration">
                      <Register
                        messageType={setMessageType}
                        message={setMessage}
                        modal={setModalOpen}
                      />
                    </Route>
                    <Route exact screen path="/application/email">
                      <Email
                        messageType={setMessageType}
                        message={setMessage}
                        modal={setModalOpen}
                      />
                    </Route>
                    <Route exact screen path="/application/transactions">
                      <TransactionHistory />
                    </Route>
                  </Navigation>
                </Router>
              </div>
              <Footer />
            </div>
          </ModalSheetProvider>
        </div>
      </>
    );
  } else {
    return (
      <div className="rotate">
        <FontAwesomeIcon icon={faSync} />
        <span className="rotate__text">
          {' '}
          Por favor, vire o seu dispositivo.{' '}
        </span>
      </div>
    );
  }
}

export default App;
