import React from 'react';
import Modal from 'react-modal';
import ScrollFade from '@benestudioco/react-scrollfade/dist/ScrollFade';
import Button from './Button';

const TermsModal = (props) => {
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  const navigateCookies = () => {
     window.open('https://www.allaboutcookies.org/verify')
  };

  const navigateDeleteCookies = () => {
     window.open('https://dovelhosefaznovo.pt/cookie-disable')
  };


  return (
    <Modal isOpen={props.modalOpen} style={customStyles}>
      <a className="termsmodal__title" onClick={'https://dovelhosefaznovo.pt/doc/RVM_Regulamento.pdf'}>
        {' '}
        Política de Privacidade e Política de Cookies{' '}
      </a>
      <div className="termsmodal__gradient">
        <ScrollFade />
        <span className="termsmodal__text">
          {' '}
          <span className='termsmodal__paragraph'> Usamos cookies em todos os nossos sites para melhorar o desempenho e a sua experiência como utilizador. Esta página explica como o fazemos. </span>
          
          <span className='termsmodal__subtitle'> O que são? </span>
          
          <span className='termsmodal__paragraph'> Os cookies são pequenos ficheiros de texto que um site, ao ser visitado pelo utilizador, coloca no seu computador ou no seu dispositivo móvel através do navegador de internet (browser). A colocação de cookies ajudará o site a reconhecer o seu dispositivo na próxima vez que o utilizador o visita. </span>
          <span className='termsmodal__paragraph'> Usamos o termo cookies nesta política para referir todos os ficheiros que recolhem informações desta forma. </span>
          <span className='termsmodal__paragraph'> Os cookies utilizados não recolhem informação que identifica o utilizador. Os cookies recolhem informações genéricas, designadamente a forma como os utilizadores chegam e utilizam os sites ou a zona do país/países através do qual acedem ao site, etc. </span>
          <span className='termsmodal__paragraph'> Os cookies retêm apenas informação relacionada com as suas preferências. </span>
          <span className='termsmodal__paragraph'> A qualquer momento o utilizador pode, através do seu navegador de internet (browser) decidir ser notificado sobre a receção de cookies, bem como bloquear a respetiva entrada no seu sistema. </span>
          <span className='termsmodal__paragraph'> A recusa de uso de cookies no site, pode resultar na impossibilidade de ter acesso a algumas das suas áreas ou de receber informação personalizada. </span>
          
          <span className='termsmodal__subtitle'> Para que servem? </span>
          
          <span className='termsmodal__paragraph'> Os cookies são usados para ajudar a determinar a utilidade, interesse e o número de utilizações dos sites, permitindo uma navegação mais rápida e eficiente e eliminando a necessidade de introduzir repetidamente as mesmas informações. </span>
          
          <span className='termsmodal__subtitle'> Tipo de cookies utilizados </span>
          
          <span className='termsmodal__paragraph'> Cookies essenciais  - Alguns cookies são essenciais para aceder a áreas específicas do nosso site, tal como aceder a áreas seguras do site através de login. </span>
          <span className='termsmodal__paragraph'> Cookies analíticos  - Utilizamos estes cookies para analisar a forma como os utilizadores usam o site e monitorar a performance deste. Isto permite-nos fornecer uma experiência de alta qualidade ao personalizar a nossa oferta e rapidamente identificar e corrigir quaisquer problemas que surjam. Por exemplo, usamos cookies de desempenho para saber quais as páginas mais populares, qual o método de ligação entre páginas que é mais eficaz, ou para determinar a razão de algumas páginas estarem a receber mensagens de erro. </span>
          <span className='termsmodal__paragraph'> Cookies de funcionalidade  - Utilizamos cookies de funcionalidade para nos permitir relembrar as preferências do utilizador. </span>
          <span className='termsmodal__paragraph'> Cookies de terceiros  - Servem para medir o sucesso de aplicações e a eficácia da publicidade de terceiros. </span>
          <span className='termsmodal__paragraph'> Cookies de publicidade  - Servem para direcionar a publicidade em função dos interesses de cada utilizador, permitindo limitar o número de vezes do visionamento do anúncio. Estes cookies ajudam a medir a eficácia da publicidade. No entanto, não identificam o utilizador. </span>
          
          <span className='termsmodal__subtitle'> Permanentes ou temporários? </span>
          
          <span className='termsmodal__paragraph'> Cookies permanentes  - Ficam armazenados ao nível do navegador de internet (browser) nos seus dispositivos de acesso (pc, mobile e tablet) e são utilizados sempre que o utilizador faz uma nova visita ao site. Geralmente são utilizados para direcionar a navegação de acordo com os interesses do utilizador, permitindo-nos prestar um serviço mais personalizado. </span>
          <span className='termsmodal__paragraph'> Cookies de sessão  - São temporários, permanecem nos cookies do seu navegador de internet (browser) até sair do site. A informação obtida permite identificar problemas e fornecer uma melhor experiencia de navegação. </span>
          
          <span className='termsmodal__subtitle'> Bloquear cookies </span>
          
          <span className='termsmodal__paragraph'> Os cookies ajudam-no a tirar o máximo proveito dos nossos sites. </span>
          <span className='termsmodal__paragraph'> Depois de autorizar o uso de cookies, o utilizador pode sempre desativar os nossos cookies. Utilizando as ferramentas do browser, ou clicando <a className='termsmodal__link' onClick={navigateDeleteCookies}> aqui. </a> </span>
          <span className='termsmodal__paragraph'> Recordamos que ao desativar os cookies, partes do nosso site podem não funcionar corretamente. </span>
          
          <span className='termsmodal__subtitle'> Mais informações? </span>
          <a className='termsmodal__link' onClick={navigateCookies}> www.allaboutcookies.org </a>  {' '}
        </span>
      </div>
      <Button
        onclick={props.setModalOpen}
        className={'button button--primary'}
        text={'Close'}
      />
    </Modal>
  );
};

export default TermsModal;
