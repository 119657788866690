import React, { useEffect, useState, useRef } from 'react';
import mapboxgl from '!mapbox-gl';
import logo from '../../../assets/images/logo.png';
import Button from '../Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocation } from '@fortawesome/pro-light-svg-icons';
const mapbox_access_token ='pk.eyJ1Ijoib2xleC15YWtvdiIsImEiOiJjbGY4ZjBkYXcxcGpiM3RvMTJtMW8zMmozIn0.Of_d6RFLTJxu6_XptQDPDA';

// 'pk.eyJ1IjoiZmFicmljOCIsImEiOiJjaWc5aTV1ZzUwMDJwdzJrb2w0dXRmc2d0In0.p6GGlfyV-WksaDV_KdN27A';
export const Mapbox = (props) => {
  const [map, setMap] = useState(null);
  const mapContainer = useRef(null);
  const [currentMarkers, setCurrentMarkers] = useState(null);
  const [openF, setOpenF] = useState(false);

  useEffect(() => {
    if (props.userLocation && map != null && props.state == true && !openF) {
      setTimeout(() => {
        map.flyTo({
          center: [props.userLocation.longitude, props.userLocation.latitude],
          essential: true,
          zoom: 10,
        });
        map.resize();
      }, 2000);
      setOpenF(true);
    }
  }, [props.state]);

  useEffect(() => {
    if (map) {
      const markers = [];
      props.stores &&
        props.stores.length > 0 &&
        props.stores.map((element, index) => {
          if (currentMarkers !== null) {
            for (let i = 0; i < currentMarkers.length; i++) {
              currentMarkers[i].remove();
            }
          }

          var el = document.createElement('div');
          let marker_img =
            element && element.store_brand && element.store_brand.image_path
              ? element.store_brand.image_path
              : logo;
          el.innerHTML =
            props.selectedStore && element.id === props.selectedStore.id
              ? `<div style="border-radius:30px; opacity: 1.0;">
                <div style="padding:4px 8px 4px 8px; background-color:#ffffff; border: 2px solid #DD1921; border-radius:8px;">
                <img src=${marker_img}
                  alt="Marcador loja" style="width:16px; height: 16px; object-fit:contain;"/>
                </div>
                <p style="color:red;margin-top: -8px; text-align:center; color:#DD1921;">&#9660;</p>
                </div>`
              : `<div style="border-radius:30px; opacity: 0.9;">
                <div style="padding:4px 8px 4px 8px; background-color:#ffffff; border-radius:8px;">
                  <img src=${marker_img}
                    alt="Marcador loja" style="width:16px; height: 16px; object-fit:contain;"/>
                </div>
                <p style="color:#ffffff;margin-top: -8px; text-align:center;">&#9660;</p>
              </div>`;

          el.addEventListener('click', function () {
            props.changeSelectedStore(element);
            props.onMarkerClick(element);
          });

          const marker = new mapboxgl.Marker(el)
            .setLngLat({
              lng: element.longitude || 0,
              lat: element.latitude || 0,
            })
            .addTo(map);

          markers.push(marker);
        });

      setCurrentMarkers(markers);

      if (props.selectedStore) {
        map.flyTo({
          center: [props.selectedStore.longitude, props.selectedStore.latitude],
          essential: true,
          zoom: 12,
        });
      }
      // else if (props.startLocation) {
      //   map.flyTo({
      //     center: [props.startLocation.longitude, props.startLocation.latitude],
      //     essential: true,
      //     zoom: 10,
      //   });
      // }
    }
  }, [props.selectedStore]);

  useEffect(() => {
    const timer = setTimeout(() => {
      mapboxgl.accessToken = mapbox_access_token;
      const initializeMap = ({ setMap, mapContainer }) => {
        const map = new mapboxgl.Map({
          container: mapContainer.current,
          style: 'mapbox://styles/olex-yakov/clfwmxkte003g01qhpfhl16hq', // stylesheet location
          center: [
            props.startLocation ? props.startLocation.longitude : -8.130356,
            props.startLocation ? props.startLocation.latitude : 39.695882,
          ],
          zoom: props.startLocation ? 10 : 5.2,
          attributionControl: false,
          projection: "mercator"

        });

        map.on('load', () => {
          setMap(map);
          map.resize();
        });

        map.on('idle', function () {
          map.resize();
        });

        map.on('render', () => {
          map.resize();
        });
      };

      const renderMarkers = () => {
        const markers = [];
        props.stores &&
          props.stores.length > 0 &&
          props.stores.map((element, index) => {
            var el = document.createElement('div');

            var el = document.createElement('div');
            let marker_img =
              element && element.store_brand && element.store_brand.image_path
                ? element.store_brand.image_path
                : logo;
            el.innerHTML =
              props.selectedStore && element.id === props.selectedStore.id
                ? `<div style="border-radius:30px; opacity: 1.0;">
                  <div style="padding:4px 8px 4px 8px; background-color:#ffffff; border: 2px solid #DD1921; border-radius:8px;">
                  <img src=${marker_img}
                    alt="Marcador loja" style="width:16px; height: 16px; object-fit:contain;"/>
                  </div>
                  <p style="color:red;margin-top: -8px; text-align:center; color:#DD1921;">&#9660;</p>
                  </div>`
                : `<div style="border-radius:30px; opacity: 0.9;">
                  <div style="padding:4px 8px 4px 8px; background-color:#ffffff; border-radius:8px;">
                    <img src=${marker_img}
                      alt="Marcador loja" style="width:16px; height: 16px; object-fit:contain;"/>
                  </div>
                  <p style="color:#ffffff;margin-top: -8px; text-align:center;">&#9660;</p>
                </div>`;

            el.addEventListener('click', function () {
              props.changeSelectedStore(element);
              props.onMarkerClick(element);
            });

            const marker = new mapboxgl.Marker(el)
              .setLngLat({ lng: element.longitude, lat: element.latitude })
              .addTo(map);

            markers.push(marker);
          });

        setCurrentMarkers(markers);
      };

      if (!map) initializeMap({ setMap, mapContainer });
      else if (map && props.stores) {
        renderMarkers();
      }
    }, 300);

    return () => clearTimeout(timer);
  }, [map]);

  const focusOnUser = () => {
    map.flyTo({
      center: [props.userLocation.longitude, props.userLocation.latitude],
      essential: true,
      zoom: 10,
    });
  };

  return (
    <div className={'mapboxgl-custom-container'}>
      <div ref={(el) => (mapContainer.current = el)} />
      {map && (
        <div className="map__user-button">
          {/* <Button
            onclick={focusOnUser}
            className={'button--points'}
            text={<FontAwesomeIcon icon={faLocation} size="xs" />}
          /> */}
        </div>
      )}
    </div>
  );
};
