export const isValidEmail = (email) => {
  let regEmail =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  if (!regEmail.test(email)) {
    return false
  } else {
    return true
  }
}

export const isPasswordValid = (password, passwordConfirm) => {
  if (passwordConfirm.length > 0) {
    if (passwordConfirm == password && password.length > 5) return true
    else return false
  }else{
    if (password.length > 5) return true
  }
}

export const ifBothPasswordsValid = (password, passwordConfirm) => {
  if (password.length > 0) {
    if (passwordConfirm == password && password.length > 5) return true
    else return false
  }
  else {
    if (passwordConfirm == 0) return true
    else return false
  }
}

export const isPhoneValid = (phone) => {
  const test = /^$|^(9[1236][0-9]{7})$/

  if (test.test(phone)) return true
  else return false
}
