import React, {
  Fragment,
  useEffect,
  useState,
  useRef,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import CustomSelect from './Select';
import mapImage from '../../assets/images/map-image.png';
import mapAnimation from '../../assets/images/animation-one.gif';
import mapAnimationMob from '../../assets/images/animation-one-mob.gif';
import useGlobal from '../store';
import Button from './Button';
import { BottomSheet } from '../components/BottomSheet/BottomSheet.tsx';
import { Mapbox } from './Mapbox/Mapbox.tsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import dropImage from '../../assets/images/drop.svg';

import { ModalSheetContext } from '../contexts/ModalSheet.tsx';

function detectMob() {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
}

const Map = (props) => {
  const [globalState, globalActions] = useGlobal();
  const [modalOpen, setModalOpen] = useState(false);
  const [showMap, setShowMap] = useState(true);
  const [selectedStore, setSelectedStore] = useState();
  const { showModal, handleOpen, handleClose } = useContext(ModalSheetContext);

  const changeSelectedStore = (store) => {
    setSelectedStore(store);
    showModal(
      <Fragment>
        <p
          style={{
            fontWeight: 600,
            fontSize: 18,
            color: '#297B6D',
            marginBottom: 16,
          }}>
          {store && store.name}
        </p>
        <p
          style={{
            fontWeight: 500,
            fontSize: 14,
            color: '#297B6D',
            marginBottom: 24,
          }}>
          {store && store.address}
        </p>
        <Button
          onclick={() => openStore(store)}
          className={'button--primary'}
          text={'Obter direções'}
          icon={<FontAwesomeIcon icon={faArrowRight} size="xs" />}
          iconToRight={true}
        />
      </Fragment>,
      false
    );
  };

  const openStore = (store) => {
    setSelectedStore(null);
    handleClose();
    window.open(store.external_map_url);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedStore(null);
    handleClose();
  };

  const textStyle = {
    alignSelf: "center",
    textAlign: "center"
  };

  return (
    <div className="map">
      <div className="map__initial-box initial-map-box" id="map-initial-box">
        <div className={'initial-map-box__image'}>
          {detectMob() ? (
            <img src={mapAnimationMob} alt="map-animation-mob" />
          ) : (
            <img src={mapAnimation} alt="map-animation" />
          )}
        </div>
        <div className="initial-map-box__text-box">
          <p className={'map__main-title'}>
            Deposit <br /> <b>Your packages</b>
          </p>
        </div>
        <div className={'initial-map-box__actions'}>
          <Button
            iconToLeft={true}
            icon={<img src={dropImage} className="button__image" />}
            onclick={props.blockedButton ? null : props.click}
            className={'button button--primary'}
            text={' Map'}
          />
        </div>
      </div>
      <div className="map__expanded-box expanded-map-box" id="map-expanded-box">
        <div className="expanded-map-box__content" id="map-content-box">
          <Button
            onclick={props.blockedButton ? null : props.click}
            className={'button button--back'}
            text={<FontAwesomeIcon icon={faArrowLeft} />}
          />
          <div className="expanded-map-box__text-box" id="map-text-box">
            <p className={'expanded-map-box__title'}>
              Deposit <br /> <b>Your packages</b>
            </p>
            <p className="expanded-map-box__subtitle">
              At the end, collect the ticket from the machine and keep it until you receive your points.
            </p>
          </div>
          <div className={'expanded-map-box__image'} id={'map-image'}>
            {detectMob() ? (
              <img src={mapAnimationMob} alt="map-animation-mob" />
            ) : (
              <img src={mapAnimation} alt="map-animation" />
            )}
          </div>
        </div>
        <div className={'expanded-map-box__actions'} id="map-content-actions">
          {showMap && (
            <div className={'expanded-map-box__map-content'}>
              <Mapbox
                // userLocation={globalState.maps.user}
                userLocation={{ latitude: 38.742684, longitude: -9.1961042 }}
                state={props.state}
                stores={globalState.maps.stores}
                selectedStore={selectedStore}
                changeSelectedStore={(s) => setSelectedStore(s)}
                onMarkerClick={(m) => changeSelectedStore(m)}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Map;
