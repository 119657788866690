import gsap from 'gsap';
import $ from 'jquery';
// import { Timeline } from 'gsap/gsap-core';

gsap.config({
  nullTargetWarn: false,
});

export const fadeScreen = (setIsLoading) => {
  const animation = gsap.timeline({
    onComplete: function () {
      setIsLoading(false);
    },
  });
  animation.to(
    $('#loading-screen'),
    {
      duration: 0.4,
      autoAlpha: 0,
      display: 'none',
    },
    0
  );
};

export const OpenMapCard = (blockButton, setBlockButton) => {
  if (!blockButton) {
    const animation = gsap.timeline({
      onComplete: function () {
        setBlockButton(false);
      },
    });

    animation
      .to($('#card2'), { duration: 1, ease: 'power2.out', x: +600 }, 0.2)
      .to($('#card3'), { duration: 1, ease: 'power2.out', y: +600 }, 0.2)
      .to(
        '#card1',
        {
          width: '100%',
          height: '100%',
        },
        0.2
      )
      .to(
        '#card1',
        {
          paddingRight: 0,
        },
        0
      )
      .to(
        '#map-initial-box',
        {
          duration: 0.2,
          autoAlpha: 0,
          display: 'none',
        },
        0
      )
      .to(
        '#map-expanded-box',
        {
          duration: 1,
          autoAlpha: 1,
          display: 'grid',
        },
        0.8
      )
      .fromTo(
        '#map-content-box',
        {
          duration: 0.8,
          autoAlpha: 0,
          y: 200,
        },
        {
          duration: 1,
          autoAlpha: 1,
          y: 0,
        },
        0.6
      )
      .fromTo(
        '#map-content-actions',
        {
          duration: 1,
          autoAlpha: 0,
          y: 200,
        },
        {
          duration: 1,
          autoAlpha: 1,
          y: 0,
        },
        0.8
      );

    animation.play();

    setBlockButton(true);
  }
};

export const CloseMapCard = (blockButton, setBlockButton) => {
  if (!blockButton) {
    const animation = gsap.timeline({
      onComplete: function () {
        setBlockButton(false);
      },
    });

    animation
      .to(
        '#map-expanded-box',
        {
          duration: 0.1,
          autoAlpha: 0,
          display: 'none',
        },
        0
      )
      .to($('#card2'), { duration: 1, ease: 'power2.out', x: 0 }, 0.2)
      .to($('#card3'), { duration: 1, ease: 'power2.out', y: 0 }, 0.2)
      .to(
        '#card1',
        {
          width: '50%',
          height: '60%',
        },
        0
      )
      .to(
        '#card1',
        {
          paddingRight: '8px',
        },
        0
      )
      .to(
        '#map-initial-box',
        {
          duration: 1,
          autoAlpha: 1,
          display: 'grid',
        },
        0.6
      );

    animation.play();

    setBlockButton(true);
  }
};

export const OpenScanCard = (blockButton, setBlockButton) => {
  if (!blockButton) {
    const animation = gsap.timeline({
      onComplete: function () {
        setBlockButton(false);
      },
    });

    animation
      .to($('#card1'), { duration: 1, ease: 'power2.out', x: -600 }, 0.2)
      .to($('#card3'), { duration: 1, ease: 'power2.out', y: +600 }, 0.2)
      .to(
        '#card2',
        {
          width: '100%',
          height: '100%',
        },
        0.2
      )
      .to(
        '#card2',
        {
          paddingLeft: 0,
        },
        0
      )
      .to(
        '#scan-initial-box',
        {
          duration: 0.2,
          autoAlpha: 0,
          display: 'none',
        },
        0
      )
      .to(
        '#scan-expanded-box',
        {
          duration: 1,
          autoAlpha: 1,
          display: 'grid',
        },
        0.8
      )
      .fromTo(
        '#scan-content-box',
        {
          duration: 0.8,
          autoAlpha: 0,
          y: 200,
        },
        {
          duration: 1,
          autoAlpha: 1,
          y: 0,
        },
        0.6
      )
      .fromTo(
        '#scan-input-box',
        {
          duration: 1,
          autoAlpha: 0,
          y: 200,
        },
        {
          duration: 1,
          autoAlpha: 1,
          y: 0,
        },
        0.7
      )
      .fromTo(
        '#scan-content-actions',
        {
          duration: 1,
          autoAlpha: 0,
          y: 200,
        },
        {
          duration: 1,
          autoAlpha: 1,
          y: 0,
        },
        0.8
      );

    animation.play();

    setBlockButton(true);
  }
};

export const CloseScanCard = (blockButton, setBlockButton) => {
  if (!blockButton) {
    const animation = gsap.timeline({
      onComplete: function () {
        setBlockButton(false);
      },
    });

    animation
      .to(
        '#scan-expanded-box',
        {
          duration: 0.1,
          autoAlpha: 0,
          display: 'none',
        },
        0
      )
      .to($('#card1'), { duration: 1, ease: 'power2.out', x: 0 }, 0.2)
      .to($('#card3'), { duration: 1, ease: 'power2.out', y: 0 }, 0.2)
      .to(
        '#card2',
        {
          width: '50%',
          height: '60%',
        },
        0
      )
      .to(
        '#card2',
        {
          paddingLeft: '8px',
        },
        0
      )
      .to(
        '#scan-initial-box',
        {
          duration: 1,
          autoAlpha: 1,
          display: 'grid',
        },
        0.6
      );

    animation.play();

    setBlockButton(true);
  }
};

export const CloseSuccessScanCard = (blockButton, setBlockButton) => {
  if (!blockButton) {
    const animation = gsap.timeline({
      onComplete: function () {
        setBlockButton(false);
      },
    });

    animation
      .to(
        '#scan-expanded-box',
        {
          duration: 0,
          autoAlpha: 0,
          x: 0,
          display: 'none',
        },
        0
      )
      .to(
        '#scan-success-screen',
        {
          duration: 0.1,
          autoAlpha: 0,
          display: 'none',
        },
        0
      )
      .to($('#card1'), { duration: 1, ease: 'power2.out', x: 0 }, 0.2)
      .to($('#card3'), { duration: 1, ease: 'power2.out', y: 0 }, 0.2)
      .to(
        '#card2',
        {
          width: '48%',
          height: '60%',
        },
        0
      )
      .to(
        '#scan-initial-box',
        {
          duration: 1,
          autoAlpha: 1,
          display: 'grid',
        },
        0.6
      );

    animation.play();

    setBlockButton(true);
  }
};

export const OpenStoreCard = (blockButton, setBlockButton) => {
  if (!blockButton) {
    const animation = gsap.timeline({
      onComplete: function () {
        setBlockButton(false);
      },
    });

    animation
      .to($('#card1'), { duration: 1, ease: 'power2.out', y: -900 }, 0.2)
      .to($('#card2'), { duration: 1, ease: 'power2.out', y: -900 }, 0.2)
      .to(
        '#card3',
        {
          paddingTop: 0,
        },
        0
      )
      .to(
        '#card3',
        {
          width: '100%',
          height: '100%',
        },
        0.2
      )
      .to(
        '#store-initial-box',
        {
          duration: 0.2,
          autoAlpha: 0,
          display: 'none',
        },
        0
      )
      .to(
        '#store-expanded-box',
        {
          duration: 1,
          autoAlpha: 1,
          display: 'flex',
        },
        0.8
      )
      .to(
        '.store-expanded-box__iframe',
        {
          duration: 1,
          autoAlpha: 1,
          display: 'flex',
        },
        0.8
      )
      .fromTo(
        '#store-content-box',
        {
          duration: 0.8,
          autoAlpha: 0,
          y: 200,
        },
        {
          duration: 1,
          autoAlpha: 1,
          y: 0,
        },
        0.6
      )
      .fromTo(
        '#store-content-message',
        {
          duration: 0.8,
          autoAlpha: 0,
          y: 200,
        },
        {
          duration: 1,
          autoAlpha: 1,
          y: 0,
        },
        0.7
      );

    // .fromTo(
    //   '#store-content-actions',
    //   {
    //     duration: 1,
    //     autoAlpha: 0,
    //     y: 200,
    //   },
    //   {
    //     duration: 1,
    //     autoAlpha: 1,
    //     y: 0,
    //   },
    //   0.8
    // );

    animation.play();

    setBlockButton(true);
  }
};

export const CloseStoreCard = (blockButton, setBlockButton) => {
  if (!blockButton) {
    const animation = gsap.timeline({
      onComplete: function () {
        setBlockButton(false);
      },
    });

    animation
      .to(
        '#store-expanded-box',
        {
          duration: 0.1,
          autoAlpha: 0,
          display: 'none',
        },
        0
      )
      .to(
        '.store-expanded-box__iframe',
        {
          duration: 0.1,
          autoAlpha: 0,
          display: 'none',
        },
        0
      )
      .to($('#card1'), { duration: 1, ease: 'power2.out', y: 0 }, 0.2)
      .to($('#card2'), { duration: 1, ease: 'power2.out', y: 0 }, 0.2)
      .to(
        '#card3',
        {
          paddingTop: '16px',
        },
        0
      )
      .to(
        '#card3',
        {
          width: '100%',
          height: '40%',
        },
        0
      )
      .to(
        '#store-initial-box',
        {
          duration: 1,
          autoAlpha: 1,
          display: 'flex',
        },
        0.6
      );

    animation.play();

    setBlockButton(true);
  }
};

export const OnScanSuccessScreenEnter = (blockButton, setBlockButton) => {
  if (!blockButton) {
    const animation = gsap.timeline({
      onComplete: function () {
        setBlockButton(false);
      },
    });

    animation
      .fromTo(
        '#scan-loading-screen',
        {
          duration: 0.6,
          autoAlpha: 1,
          x: 0,
          display: 'flex',
        },
        {
          duration: 0.6,
          autoAlpha: 0,
          x: -300,
          display: 'none',
        },
        2
      )
      .fromTo(
        '#scan-success-screen',

        {
          duration: 1,
          ease: 'power2.out',
          autoAlpha: 0,
          x: 200,
          display: 'none',
        },
        {
          duration: 1,
          ease: 'power2.out',
          x: 0,
          autoAlpha: 1,
          display: 'flex',
        },
        '+=.3'
      );

    animation.play();

    setBlockButton(true);
  }
};

export const OnScanScreenLeave = () => {
  const animation = gsap.timeline();

  animation
    .fromTo(
      '#scan-expanded-box',
      {
        duration: 0.6,
        autoAlpha: 1,
        x: 0,
        display: 'grid',
      },
      {
        duration: 0.6,
        autoAlpha: 0,
        x: -300,
        display: 'none',
      },
      0
    )
    .fromTo(
      '#scan-loading-screen',

      {
        duration: 0.3,
        ease: 'power2.out',
        autoAlpha: 0,
        x: 200,
        display: 'none',
      },
      {
        duration: 0.3,
        ease: 'power2.out',
        x: 0,
        autoAlpha: 1,
        display: 'flex',
      },
      0.6
    );

  animation.play();
};

export const OnScanScreenEnter = (
  blockButton,
  setBlockButton,
  targetScreen
) => {
  if (!blockButton) {
    const animation = gsap.timeline({
      onComplete: function () {
        setBlockButton(false);
      },
    });

    animation
      .fromTo(
        '#scan-expanded-box',
        {
          duration: 0.6,
          autoAlpha: 0,
          x: -300,
          display: 'none',
        },
        {
          duration: 0.6,
          autoAlpha: 1,
          x: 0,
          display: 'grid',
        },
        0.4
      )
      .fromTo(
        targetScreen,
        {
          duration: 0.3,
          ease: 'power2.out',
          x: 0,
          autoAlpha: 1,
        },
        {
          duration: 0.3,
          ease: 'power2.out',
          autoAlpha: 0,
          x: 200,
          display: 'none',
        },
        0
      );

    animation.play();

    setBlockButton(true);
  }
};

export const OnScanErrorScreenEnter = (blockButton, setBlockButton) => {
  if (!blockButton) {
    const animation = gsap.timeline({
      onComplete: function () {
        setBlockButton(false);
      },
    });

    animation
      .fromTo(
        '#scan-loading-screen',
        {
          duration: 0.6,
          autoAlpha: 1,
          x: 0,
          display: 'flex',
        },
        {
          duration: 0.6,
          autoAlpha: 0,
          x: -300,
          display: 'none',
        },
        2
      )
      .fromTo(
        $('#scan-error-screen'),
        {
          duration: 1,
          ease: 'power2.out',
          x: 200,
          autoAlpha: 0,
        },
        {
          duration: 1,
          ease: 'power2.out',
          autoAlpha: 1,
          x: 0,
          display: 'grid',
        },
        '+=.3'
      );
    animation.play();

    setBlockButton(true);
  }
};
