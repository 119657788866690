require('dotenv').config();
import React, { Component } from 'react';
import Slider from 'react-slick';
import eeagrantsImage from '../../assets/images/eea_grants.png';
import apedImage from '../../assets/images/aped.png';
import apiamImage from '../../assets/images/apiam.png';
import cmlImage from '../../assets/images/cml.png';
import probebImage from '../../assets/images/probeb.png';
import ptambienteImage from '../../assets/images/pt_ambiente.png';
import fundoImage from '../../assets/images/fundo.png';
import useGlobal from '../store';

const Footer = (props) => {
  const [globalState, globalActions] = useGlobal();

    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 2000,
      responsive: [
        {
          breakpoint: 700,
          settings: {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 2000,
          }
        }
      ]
    }

    if (globalState.tenant == 'bebidasmaiscirculares') {
      return (
        <div className="footer__slider clearfix">
          <div className="footer__main">
            <img
              src={eeagrantsImage}
              alt="eea grants"
              className="footer__image--main"
            />
          </div>

          <div>
            <Slider {...settings}>
              <div className="slick-track">
                <img
                  src={ptambienteImage}
                  alt="portugal ambiente"
                  className="footer__image"
                />
              </div>
              <div>
                <img src={cmlImage} alt="cm lisboa" className="footer__image" />
              </div>
              <div>
                <img src={apiamImage} alt="apiam" className="footer__image" />
              </div>
              <div>
                <img src={apedImage} alt="aped" className="footer__image" />
              </div>
              <div>
                <img src={probebImage} alt="probeb" className="footer__image" />
              </div>
            </Slider>
          </div>
        </div>
      );
    } else {
      return (
        <div className="footer__slider">
          <Slider {...settings}>
            <div className="slick-track">
              <img
                src={ptambienteImage}
                alt="portugal ambiente"
                className="footer__image"
              />
            </div>
            <div>
              <img
                src={fundoImage}
                alt="fundo ambiental"
                className="footer__image"
              />
            </div>
            <div>
              <img src={apiamImage} alt="apiam" className="footer__image" />
            </div>
            <div>
              <img src={apedImage} alt="aped" className="footer__image" />
            </div>
            <div>
              <img src={probebImage} alt="probeb" className="footer__image" />
            </div>
          </Slider>
        </div>
      );
    }
  }

export default Footer